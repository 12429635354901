import http from "./http-common"
import {Exercise, Note, Plan} from "../types/domain";

/**
 * Service class for the Plan type
 * @author KMU
 */
class PlanService {
    url = `/plans/`;

    getAll() {
        return http.get<Array<Plan>>(this.url + `getAll`);
    }

    get(id: number) {
        return http.get<Plan>(this.url + `${id}`);
    }

    getByUsername(username: string | undefined){
        return http.get<Array<Plan>>(this.url + `getByUsername/${username}`);
    }

    create(data: Plan) {
        return http.post<Plan>(this.url + `create`, data);
    }

    /**
     * Create new Plan entity with it's Planexercise entities
     * @param plan plan entity with general information
     * @param exercises list of exercises for this plan
     * @return boolean whether creation was successful or not
     */
    createWholePlan(plan: Plan, exercises: Array<Exercise>) {
        return http.post<Plan>(this.url + `createWholePlan`, {"plan": plan, "exercises": exercises});
    }

    update(data: Plan, id: number) {
        return http.put<any>(this.url + `${id}`, data);
    }

    updateWithNotes(plan: Plan, notes: Array<Note>, id: number) {
        return http.put<any>(this.url + `updateWithNotes/` + `${id}`, {"plan": plan, "notes": notes});
    }

    updateWithExercises(plan: Plan, exercises: Array<Exercise>, id: number) {
        return http.put<any>(this.url + `updateWithExercises/` + `${id}`, {"plan": plan, "exercises": exercises});
    }

    delete(id: number) {
        return http.delete<any>(this.url + `${id}`);
    }
}

export default new PlanService();