import React, {useEffect, useMemo, useState} from "react";
import {MantineReactTable, MRT_ColumnDef, useMantineReactTable} from 'mantine-react-table';
import {MRT_Localization_DE} from "mantine-react-table/locales/de";
import {Container, Row} from 'react-bootstrap';
import {Exercise, Plan, PlanExercise} from "../../types/domain";
import PlanService from "../../services/plan.service";
import {useNavigate} from "react-router-dom";
import {exportExerciseList} from "./components/utils/export/exportExerciseList";
import {sortStages} from "./components/utils/helperFunctions";
import {exportDocSheet} from "./components/utils/export/exportDocSheet";
import {ActionIcon, Box, Tooltip} from "@mantine/core";
import {IconChecklist, IconCopy, IconEdit, IconFiles, IconPencil, IconReportSearch} from "@tabler/icons-react";
import AppHeader from "../header/AppHeader";
import Cookies from "js-cookie";
import {exportAttachments} from "./components/utils/export/exportAttachments";
import StateService from "../../services/state.service";
import UseraccountService from "../../services/useraccount.service";
import CustomModal from "./components/utils/CustomModal";
import {ModalType} from "../../types/UITypes";

/**
 * @author KMU
 * Page with the list of saved plans
 */

//Plan type for table
type TablePlan = {
    id: number,
    topicName: string,
    trainingTyp: string,
    groupParticipant: string,
    stages: string,
    date: string,
    state: string
}

export default function PlanList () {

    const navigate = useNavigate();

    const [data, setData] = useState<Array<TablePlan>>([]);  //plans from database

    const [modal, setModal] = useState<ModalType>({
        show: false,
        title: "",
        body: "",
        decline: false,
        handleOk: (() => {}),
        handleCancel: (() => {})
    })

    //definition of table columns
    const columns = useMemo<MRT_ColumnDef<TablePlan>[]>(
        () => [
            {
                accessorKey: 'topicName',
                header: 'Thema'
            },
            {
                accessorKey: 'trainingTyp',
                header: 'Trainingsart'
            },
            {
                accessorKey: 'groupParticipant',
                header: 'Gruppen-/ Teilnehmername'
            },
            {
                accessorKey: 'stages',
                header: 'Stadium'
            },
            {
                accessorKey: 'date',
                header: 'Datum',
                sortingFn: (rowA, rowB, columnId) => {
                    const a = rowA.original.date.split('-').join('');
                    const b = rowB.original.date.split('-').join('');
                    return a.localeCompare(b);
                }
            },
            {
                accessorKey: 'state',
                header: 'Status'
            }
        ], []
    );

    /*Checks access of user*/
    useEffect(() => {
        if(!Cookies.get('username')) {
            navigate('/login', {replace: true})
        }
    });

    /**
     * First function that is called
     */
    useEffect(() => {
        retrievePlans();
    }, []);

    /**
     * Navigate to rework plan page
     * @param planId id of the plan to rework
     */
    async function reworkPlan(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data
        navigate('/reworkPlan', {replace: true, state: dbPlan})
    }


    async function updatePlan(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data
        navigate('/newPlan', {replace: true, state: dbPlan})
    }

    /**
     * Export exercises of plan with given id
     * @param planId plan id
     */
    async function exportExercisesEvent(planId: number){
        let dbPlan = (await PlanService.get(planId)).data
        let exercises = Array<Exercise>();
        if (dbPlan.exercises !== null)
            dbPlan.exercises.forEach((planExercise) => {
                exercises.push(planExercise.exercise);
            });

        let name = "";
        let participants = null;
        if (dbPlan.participant !== null)
            name = dbPlan.participant.firstname + " " + dbPlan.participant.lastname
        else if (dbPlan.group !== null)
            name = dbPlan.group.name
            participants = dbPlan.group?.participants

        await exportExerciseList(dbPlan.date, exercises, name, participants);
    }

    /**
     * Export plan in document sheet
     * @param planId plan id
     */
    async function exportAsDocSheet(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data
        await exportDocSheet(dbPlan);
    }

    /**
     * Get all plans from the database and convert to table format
     */
    async function retrievePlans() {
        let plans: Array<Plan> = [];
        await PlanService.getByUsername(Cookies.get('username'))
            .then((response: any) => {
                plans = response.data;
            })
            .catch((e: Error) => {
                console.log(e);
            });

        //convert to table format
        let tablePlans: Array<TablePlan> = [];
        for (const plan of plans) {
            let tablePlan: TablePlan = {
                id: plan.id,
                topicName: plan.topic.name,
                trainingTyp: plan.participant ? "ET" : "GT",
                groupParticipant: plan.participant ? plan.participant.lastname : plan.group ? plan.group.name : "leer",
                stages: plan.stages.map(s => s.name).sort((a, b) => sortStages(a, b)).join('/'),
                date: plan.date,
                state: plan.state.name
            }
            tablePlans.push(tablePlan);
        }

        setData(tablePlans);
    }

    /**
     * Export attachments of plan with given id
     * @param planId plan id
     */
    async function exportAttachmentsEvent(planId: number) {
        let dbPlan = (await PlanService.get(planId)).data

        let attachments: string[] = [];
        // get paths of all attachments and solution sheets
        dbPlan.exercises?.forEach(planExe => {
            planExe.exercise.attachments.forEach(attach => {
                attachments.push(attach.path);
                attachments.push(attach.solutionPath);
            })
        })

        let name = "";
        if (dbPlan.participant !== null)
            name = dbPlan.participant.firstname + " " + dbPlan.participant.lastname
        else if (dbPlan.group !== null)
            name = dbPlan.group.name

        await exportAttachments(attachments, name,dbPlan.date);
    }

    /**
     * Copy a plan
     * @param planId plan id of plan to be copied
     */
    async function copyPlan(planId: number) {
        const dbPlan = (await PlanService.get(planId)).data

        const planState = (await StateService.getByName("in Planung")).data;
        const dbUser = (await UseraccountService.getByName(Cookies.get('username')!)).data;

        let copiedPlan: Plan = {
            id: 0,
            date: dbPlan.date,
            duration: dbPlan.duration,
            prepTimeContent: dbPlan.prepTimeContent,
            prepTimeOrg: dbPlan.prepTimeOrg,
            timeSupport: dbPlan.timeSupport,
            exercises: null,
            stages: dbPlan.stages,
            state: planState,
            topic: dbPlan.topic,
            group: dbPlan.group,
            participant: dbPlan.participant,
            notes: [],
            user: dbUser
        }

        //create list with Exercises
        let exercises: Array<Exercise> = [];
        dbPlan.exercises?.forEach((planExe: PlanExercise) => {
            exercises.push(planExe.exercise)
        })

        await PlanService.createWholePlan(copiedPlan, exercises);

        // show success modal and redirect to overview page
        setModal({
            ...modal,
            show: true,
            title: "Hinweis",
            body: "Der Plan wurde erfolgreich kopiert!",
            decline: false,
            handleOk: () => {
                setModal({...modal, show: false});
                retrievePlans();
            },
            handleCancel: () => {
            }
        })
    }

    const table = useMantineReactTable<TablePlan>({
        columns,
        data,
        enableRowActions: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        positionActionsColumn: "last",
        renderRowActions: ({ row }) => {
            if(row.original.state === "in Planung"){
                return <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Tooltip label="Bearbeiten">
                        <ActionIcon color="blueish" onClick={() => updatePlan(row.original.id)}><IconPencil/></ActionIcon>
                    </Tooltip>
                    <Tooltip label="Kopieren">
                        <ActionIcon onClick={() => copyPlan(row.original.id)}><IconCopy/></ActionIcon>
                    </Tooltip>                </Box>
            } else if (row.original.state === "im Training"){
                return <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Tooltip label="Nachbearbeiten">
                        <ActionIcon style={{color: "var(--salmon)"}} onClick={() => reworkPlan(row.original.id)}><IconEdit/></ActionIcon>
                    </Tooltip>
                    <Tooltip label="Übungsliste">
                        <ActionIcon onClick={() => exportExercisesEvent(row.original.id)}><IconChecklist/></ActionIcon>
                    </Tooltip>
                    <Tooltip label="Anhänge">
                        <ActionIcon onClick={() => exportAttachmentsEvent(row.original.id)}><IconFiles/></ActionIcon>
                    </Tooltip>
                    <Tooltip label="Kopieren">
                        <ActionIcon onClick={() => copyPlan(row.original.id)}><IconCopy/></ActionIcon>
                    </Tooltip>
                </Box>
            } else if (row.original.state === "abgeschlossen"){
                return <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                    <Tooltip label="Dokublatt">
                        <ActionIcon onClick={() => exportAsDocSheet(row.original.id)}><IconReportSearch/></ActionIcon>
                    </Tooltip>
                    <Tooltip label="Kopieren">
                        <ActionIcon onClick={() => copyPlan(row.original.id)}><IconCopy/></ActionIcon>
                    </Tooltip>
                </Box>
            }
        },
        mantineTableBodyCellProps: ({cell}) => ({
            style: {
                color: cell.getValue() === "in Planung" ? "var(--blueish)" :
                    cell.getValue() === "im Training" ? "var(--salmon)" : "black",
            },
        }),
        localization: MRT_Localization_DE,
        enableColumnActions: false,
        paginationDisplayMode: "pages"
    });

    return (
        <div>
            <AppHeader bioLogo={false} showHomeIcon={true} showNewPlanIcon={true} showUserIcon={true}/>
            <Container className="app-content-container">
                <Row className="firstRow heading">
                    <h1>Übersicht Trainingspläne</h1>
                </Row>
                <Row>
                    <div className="planListTable">
                        <MantineReactTable
                            table={table}
                        />
                    </div>
                </Row>
                <CustomModal title={modal.title}
                             body={modal.body}
                             decline={modal.decline} showModal={modal.show} handleOk={modal.handleOk}
                             handleCancel={modal.handleCancel}
                />
            </Container>
        </div>
    );
}